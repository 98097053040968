

    var mySwiper = new Swiper('.slider-container', {
        speed: 900,
        autoplay: 5000
      });

// Select all links with hashes
$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
      &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });



$('.backToTop').click(function() {
    $('body,html').animate({
        scrollTop : 0
    }, 500);
});

function sierotki(){
    $('section p, section span, h1, h2, h3, h4, h5, h6, p, span').each(function(){
        if($(this).length > 0) {
        var $html = $(this).html();
        $html = $html.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;");
            $(this).empty().html($html);
        }
    });
}
sierotki();



$('.main__nav a').on('click', function(){
  $('.main__nav a').each(function(i,el){
    $(el).removeClass('active')
  })
  $(this).addClass('active');
});


$('.hamburger').on('click', function(){
  $(this).toggleClass('is-active');
  $('.main__nav__wrapper').toggleClass('open');
});

